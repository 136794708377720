(() => {
	$('.testimonial-slider').each(function (i, el) {
		let swiperClass = 'testimonial-slider-' + i;
		$(this).addClass(swiperClass);

		// navigation
		let prevClass = 'testimonial-button-prev-' + i;
		let nextClass = 'testimonial-button-next-' + i;
		$(this).parent().find('.swiper-button-prev').addClass(prevClass);
		$(this).parent().find('.swiper-button-next').addClass(nextClass);

		// pagination
		let pagination = 'testimonial-pagination-' + i;
		$(this).parent().find('.swiper-pagination').addClass(pagination);

		new Swiper('.' + swiperClass, {
			loop: false,
			speed: 500,
			slidesPerView: 'auto',
			spaceBetween: 40,
			centerInsufficientSlides: true,
			watchOverflow: true,
			navigation: {
				prevEl: '.' + prevClass,
				nextEl: '.' + nextClass
			},
			breakpoints: {
				768: {
					spaceBetween: 100
				}
			},
			breakpoints: {
				992: {
					spaceBetween: 150
				}
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: true
			}
		});
	});
})();
