// About popup
$(document).on('click', '.js-about-popup', function (e) {
	e.preventDefault();

	$.magnificPopup.open({
		items: {
			src: $(this).attr('href')
		},
		fixedContentPos: false,
		fixedBgPos: false,
		closeOnContentClick: false,
		enableEscapeKey: true,
		autoFocusLast: false,
		// for animation
		mainClass: 'about-popup',
		// delay removal by X to allow out-animation, same as css transition
		removalDelay: 600,
		callbacks: {
			open: function () {
				$('body').addClass('js-popup-active');
			},

			close: function () {
				$('body').removeClass('js-popup-active');
			}
		}
	});
});
