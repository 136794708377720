(() => {
	let lastOpenIndex = 0; // Remember the last opened accordion index

	const verticalAccordion = function verticalAccordion(section) {
		const accordions = section.querySelectorAll('.accordion');
		const accHeadings = section.querySelectorAll('.accordion-heading');
		const accContents = section.querySelectorAll('.accordion-content');
		const images = section.querySelectorAll('.image-col .img-cover');

		const activateAccordion = index => {
			accordions.forEach((accordion, i) => {
				const accContent =
					accordion.querySelector('.accordion-content');
				if (i === index) {
					accordion.classList.add('js-active');
					accContent.style.maxHeight = accContent.scrollHeight + 'px';
					images[i].classList.add('js-active');
					lastOpenIndex = index; // Update the last opened index
				} else {
					accordion.classList.remove('js-active');
					accContent.style.maxHeight = null;
					images[i].classList.remove('js-active');
				}
			});
		};

		// Loop over headings and add a click event listener
		accHeadings.forEach((accHeading, index) => {
			accHeading.addEventListener('click', () =>
				activateAccordion(index)
			);
		});

		// Set the last opened accordion and image to active
		if (accordions.length > 0 && images.length > 0) {
			activateAccordion(lastOpenIndex);
		}
	};

	const initVerticalAccordions = () => {
		const sections = document.querySelectorAll('.image-accordion-section');
		sections.forEach(section => verticalAccordion(section));
	};

	initVerticalAccordions();
	window.addEventListener('resize', debounce(initVerticalAccordions, 200));
	window.addEventListener(
		'orientationchange',
		debounce(initVerticalAccordions, 200)
	);
})();
