(() => {
	let swiperInstances = [];

	const initSwiper = () => {
		$('.team-cards-slider').each(function (i, el) {
			let swiperClass = 'team-cards-slider-' + i;
			$(this).addClass(swiperClass);

			// pagination
			let pagination = 'team-cards-pagination-' + i;
			$(this).parent().find('.swiper-pagination').addClass(pagination);

			if (window.innerWidth <= 992) {
				let swiper = new Swiper('.' + swiperClass, {
					loop: false,
					speed: 500,
					slidesPerView: 'auto',
					spaceBetween: 16,
					centerInsufficientSlides: true,
					watchOverflow: true,
					freeMode: {
						enabled: true
					},
					pagination: {
						el: '.' + pagination,
						clickable: true
					}
				});
				swiperInstances.push(swiper);
			}
		});
	};

	const destroySwiper = () => {
		swiperInstances.forEach(swiper => {
			if (swiper && !swiper.destroyed) {
				swiper.destroy(true, true);
			}
		});
		swiperInstances = [];
	};

	const handleResize = () => {
		if (window.innerWidth > 992) {
			destroySwiper();
		} else {
			if (swiperInstances.length === 0) {
				initSwiper();
			}
		}
	};

	// Debounce the resize handler with a delay of 200ms
	const debouncedHandleResize = debounce(handleResize, 200);

	// Initial setup
	initSwiper();

	// Add resize event listener with debounced function
	window.addEventListener('resize', debouncedHandleResize);
})();
